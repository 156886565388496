import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import directoryStyles from './directory.module.css'
import School from './School'

const Directory = (props) => {

  const rawSchoolsData = useStaticQuery(
    graphql`
      query {
        allSchools {
          edges {
            node {
              schools {
                address
                email
                name
                phone
                about
                website
                country
                promoted
                features
              }
            }
          }
        }
      }
    `
  )

  const directoryData = rawSchoolsData.allSchools.edges[0].node.schools

  const createNestedSchoolStructure = (flatSchoolStructure) => {
    const nestedSchoolsByCountry = flatSchoolStructure.reduce((nested, school) => {
      if (!school.country) { // XXX Filter out empty data. Only required during initial development. Before go live, we don't need to do this as we will make sure our database is correct.
        return nested
      }

      if (!nested[school.country]) {
        nested[school.country] = []
      }
      nested[school.country].push(school)
      return nested
    }, {})
    
    return Object.keys(nestedSchoolsByCountry).sort().map(countryName => {
      return {
        name: countryName,
        schools: nestedSchoolsByCountry[countryName]
      }
    })
  }

  const toggleCountryHandler = (countryData) => {

    const updatedCountries = state.countries;
    const selectedCountry = updatedCountries.find(country => country.name === countryData.name)
    selectedCountry.expanded = !selectedCountry.expanded

    setState({countries: updatedCountries})
  }

  const toggleSchoolHandler = (countryKey, schoolKey) => {
    const school = {...state.countries[countryKey].schools[schoolKey]}

    school.expanded = !school.expanded

    const countries = [...state.countries]

    countries[countryKey].schools[schoolKey] = school

    setState({countries: countries})
  }

  const getSchoolsUI = (countryData, countryIndex) => {
    if (countryData.expanded) {
      return <div className={`${directoryStyles.schools}`}>
        {countryData.schools.map((school, i) => {
          return <School key={i} school={school} expand={() => {toggleSchoolHandler(countryIndex, i)}}></School>
        })}
      </div> 
    }
    return '' 
  }

  const [state, setState] = useState({
    countries: createNestedSchoolStructure(directoryData)
  })

  const getArrowClass = (countryData) => {
    return countryData.expanded ? 'arrow-up' : 'arrow-down'
  }

  return <section>
    <h2 id="directory">Kite School Directory</h2>

    {state.countries.map((countryData, countryIndex) => {
      return <div key={countryIndex}>
        <h3 className={directoryStyles.countryHeader} onClick={() => {toggleCountryHandler(countryData)}}>
          {countryData.name} <span className={`arrow ${getArrowClass(countryData)}`}></span>
        </h3>
        {getSchoolsUI(countryData, countryIndex)}
      </div>
    })}

  </section>
}

export default Directory
