import React from 'react'

import SEO from '../components/seo'
import Cover from '../components/Cover/Cover'
import Directory from '../components/Directory/Directory'
import Faqs from '../components/Faq/Faqs'
import Layout from '../components/layout'
import Splash from '../components/Splash/Splash'
import airushCarve from '../components/Cover/airush-carve.jpg'
import jumpPhoto from '../components/Cover/kiteboarding-jump.jpg'
import groupKiting from '../components/Splash/group-kiting.jpg'

export default function Home() {
  const getCoverBackgroundStyle = (url) => {
    return `
      url(${url}) #B7DBCF no-repeat center center fixed
    `
  }

  return <Layout>
    <SEO title={'Can I Teach Myself Kiteboarding'} ogImage={groupKiting}></SEO>
    <Splash></Splash>
    <p>In short, yes. But you almost certainly shouldn't. For the sake of not getting hurt, not hurting someone else, not getting kiting banned at your beach, saving money and saving time <strong>please don't teach yourself kiteboarding</strong>.</p>
    <p>To find out more, read some of the <a href="#faqs">FAQs</a>. Or save yourself some time and jump straight to our <a href="#directory">kite school directory</a> and find somewhere awesome to take lessons.</p>
    <Cover backgroundStyle={getCoverBackgroundStyle(jumpPhoto)}>&nbsp;</Cover>
    <Faqs></Faqs>
    <Cover backgroundStyle={getCoverBackgroundStyle(airushCarve)}>&nbsp;</Cover>
    <Directory></Directory>
  </Layout>
}
