import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Faq from './Faq'

const Faqs = (props) => {
  const rawFaqData = useStaticQuery(
    graphql`
      query {
        allFaqsJson {
          edges {
            node {
              question
              answer
              elementId
              expanded
            }
          }
        }
      }
    `
  )

  const faqData = rawFaqData.allFaqsJson.edges.map(edge => edge.node)

  const [ state, setState ] = useState({
    faqs: faqData
  })

  const toggleFaqAnswerHandler = (question) => {
    const faqIndex = state.faqs.findIndex(faq => faq.question === question)

    const faq = {...state.faqs[faqIndex]};

    faq.expanded = !faq.expanded;

    const faqs = [...state.faqs];
    faqs[faqIndex] = faq;

    setState({faqs: faqs});
  }

  return <section>
    <h2 id="faqs">FAQs</h2>
    <div className="stack">
      {state.faqs.map((faq, i) => {
        return <Faq
          key={i}
          question={faq.question}
          expanded={faq.expanded}
          expand={toggleFaqAnswerHandler}
          elementId={faq.elementId}
        >{faq.answer}</Faq>
      })}
    </div>
  </section>
}

export default Faqs
