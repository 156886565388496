import React from 'react'

import faqStyles from './faq.module.css'


const faq = (props) => {

  const answer = props.expanded ? props.children : '';

  const arrowClass = props.expanded ? 'arrow-up' : 'arrow-down'

  return <article className="faq">
    <h3 className={faqStyles.faqQuestion} onClick={() => props.expand(props.question)} id={props.elementId}>
      {props.question} <span className={`arrow ${arrowClass}`}></span>
    </h3>
    <div dangerouslySetInnerHTML={{__html: answer}}></div>
  </article>
};

export default faq;
